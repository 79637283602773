<template>
    <section class="relative z-10 mx-auto -mt-32 max-w-7xl px-4 pb-32 sm:px-6 lg:px-8" aria-labelledby="event-heading">
        <div class="bg-white px-8 py-8 rounded-2xl border-b-2 border-b-yellow-200">
            <app-event-calender-skeleton v-if="loading" />
            <div v-else>
                <h2 class="sr-only" id="event-heading">Aankomende activiteiten</h2>
                <app-event-calender :events="events" :hide-upcoming-events-label="true" :show-go-to-agenda-button="true"></app-event-calender>
            </div>
        </div>
    </section>
</template>

<script>
import EventService from '@/services/event.service';
import AppEventCalenderSkeleton from '../loaders/AppEventCalendarSkeleton.vue';
import AppEventCalender from '../events/AppEventCalender.vue';

export default {
    components: {
        AppEventCalenderSkeleton,
        AppEventCalender,
    },
    data() {
        return {
            loading: true,
            events: []
        }
    },
    mounted() {
        this.fetchEvents();
    },
    methods: {
        async fetchEvents() {
            await EventService.all(1, 3).then(
                (res) => {
                    this.events = res.data;
                    this.loading = false;
                },
                () => {
                    this.loading = false;
                }
            )
        }
    }
}
</script>
