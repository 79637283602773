<template>
    <div class="container mx-auto p-4">
        <div class="lg:grid lg:grid-cols-12 lg:gap-4">
            <div class="lg:col-span-5 xl:col-span-4 lg:order-last">
                <div
                    class="text-base font-semibold leading-6 text-gray-300 bg-gray-200 rounded h-6 w-48 mb-4 animate-pulse lg:hidden">
                </div>

                <div class="flex justify-between items-center mb-4">
                    <div class="h-5 w-5 bg-gray-200 rounded animate-pulse"></div>
                    <div class="text-sm font-semibold bg-gray-200 rounded h-6 w-32 animate-pulse"></div>
                    <div class="h-5 w-5 bg-gray-200 rounded animate-pulse"></div>
                </div>

                <div class="grid grid-cols-7 gap-1 mb-2">
                    <div v-for="i in 7" :key="i" class="text-xs leading-6 bg-gray-200 rounded h-4 animate-pulse"></div>
                </div>

                <div class="grid grid-cols-7 gap-1 mb-4">
                    <div v-for="i in 28" :key="i" class="bg-gray-200 rounded h-6 animate-pulse"></div>
                </div>

                <div class="rounded-md bg-gray-200 h-8 w-full animate-pulse mb-6"></div>
            </div>

            <div class="lg:col-span-7 xl:col-span-8">
                <div
                    class="hidden lg:block text-base font-semibold leading-6 text-gray-300 bg-gray-200 rounded h-6 w-48 mb-4 animate-pulse">
                </div>

                <div v-for="i in 3" :key="i" class="flex space-x-4 animate-pulse mb-4">
                    <div class="flex-none h-24 w-24 bg-gray-200 rounded-lg"></div>
                    <div class="flex-grow space-y-3 py-1">
                        <div class="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div class="space-y-2">
                            <div class="h-4 bg-gray-200 rounded"></div>
                            <div class="h-4 bg-gray-200 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
