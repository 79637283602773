<template>
    <footer class="bg-white" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <div class="space-y-8 xl:col-span-1">
                    <img class="h-12" :src="require('@/assets/img/png/logo.png')" alt="logo" />
                    <p class="text-base text-gray-500">Personeelsvereniging Majstro is een officiële vereniging.
                        De statuten zijn gedeponeerd bij de notaris en volgens deze statuten en het huishoudelijk
                        reglement handelt het bestuur.</p>
                    <div class="flex space-x-6">
                        <a :href="navigation.social.href" target="_blank" class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">{{ navigation.social.name }}</span>
                            <component :is="navigation.social.icon" class="h-10 w-10" aria-hidden="true" />
                        </a>
                    </div>
                </div>
                <div class="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-base font-medium text-gray-900">PV Majstro</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li>
                                    <p class="flex gap-4 text-gray-500">
                                        <span class="sr-only">Straat en huisnummer</span>
                                        <HomeIcon class="h-6 w-6" /> IABC 5104
                                    </p>
                                </li>
                                <li>
                                    <p class="flex gap-4 text-gray-500">
                                        <span class="sr-only">Postcode en plaats</span>
                                        <MapPinIcon class="h-6 w-6" /> 4814 RN Breda
                                    </p>
                                </li>
                                <li>
                                    <p class="text-gray-500 hover:text-gray-900 underline">
                                        <span class="sr-only">Telefoonnummer</span>
                                        <a class="flex gap-4" href="tel:0765232222">
                                            <PhoneIcon class="h-6 w-6" /> 076 523 2222
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <span class="sr-only">KVK nummer</span>
                                    <p class="flex gap-4 text-gray-500">
                                        <BriefcaseIcon class="h-6 w-6" /> <span>KVK: 65348427</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-12 md:mt-0">
                            <h3 class="text-base font-medium text-gray-900">Organisatie</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.about" :key="item.name">
                                    <a :href="item.href" class="text-base text-gray-500 hover:text-gray-900">{{
                                        item.name
                                    }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-base font-medium text-gray-900">Algemeen</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.general" :key="item.name">
                                    <a :href="item.href" class="text-base text-gray-500 hover:text-gray-900">{{
                                        item.name
                                    }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-12 md:mt-0">
                            <h3 class="text-base font-medium text-gray-900">Contact</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.support" :key="item.name">
                                    <a :href="item.href" class="text-base text-gray-500 hover:text-gray-900">{{
                                        item.name
                                    }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-12 border-t border-gray-200 pt-8">
                <p class="text-base text-gray-400 xl:text-center">&copy; <span>{{ new Date().getFullYear() }}</span>
                    - Made with
                    <HeartIcon class="w-5 h-5 inline-flex text-red-400" /> by: Bjarne & <a href="https://www.besolve.nl"
                        target="_blank">Besolve</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import { defineComponent, h } from 'vue'
import { HomeIcon, MapPinIcon, PhoneIcon, BriefcaseIcon, HeartIcon } from '@heroicons/vue/24/solid';

export default {
    components: {
        defineComponent,
        h,
        HomeIcon,
        MapPinIcon,
        PhoneIcon,
        BriefcaseIcon,
        HeartIcon
    },
    data() {
        return {
            navigation: {
                about: [
                    { name: 'Het bestuur', href: '/het-bestuur' },
                    { name: 'Reglement', href: '/reglement' },
                    { name: 'Algemene voorwaarden', href: '/algemene-voorwaarden' },
                    { name: 'Jaarverslagen', href: '/jaarverslagen' },
                    { name: 'Lid worden', href: '/lidmaatschap' },
                ],
                general: [
                    { name: 'Agenda', href: '/agenda' },
                    { name: 'Suggesties', href: '/suggesties' },
                    { name: 'Uitschrijven nieuwsbrief', href: '/uitschrijven-nieuwsbrief'}
                ],
                support: [
                    { name: 'Stuur een e-mail', href: 'mailto:pvjumbobreda@jumbo.com' }
                ],
                social:
                {
                    name: 'Facebook',
                    href: 'https://www.facebook.com/PVmajstro/',
                    icon: defineComponent({
                        render: () =>
                            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
                                h('path', {
                                    'fill-rule': 'evenodd',
                                    d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                                    'clip-rule': 'evenodd',
                                }),
                            ]),
                    }),
                },
            }
        }
    }
}
</script>