<template>
    <Popover class="relative bg-white shadow-sm">
        <div class="mx-auto max-w-7xl px-4 sm:px-6">
            <div class="flex items-center justify-between py-4 md:justify-start md:space-x-10">
                <div class="flex justify-start lg:w-0 lg:flex-1">
                    <a href="/">
                        <span class="sr-only">PV Majstro</span>
                        <img class="h-12 w-auto" :src="require('@/assets/img/png/logo.png')" alt="logo" />
                    </a>
                </div>
                <div class="-my-2 -mr-2 md:hidden">
                    <PopoverButton
                        class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                        <span class="sr-only">Open menu</span>
                        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                </div>
                <PopoverGroup as="nav" class="hidden space-x-10 md:flex">
                    <Popover class="relative z-20" v-slot="{ open }">
                        <PopoverButton
                            :class="[open ? 'text-gray-900' : 'text-gray-500', 'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none']">
                            <BuildingOffice2Icon class="h-6 w-6 mr-1" />
                            <span>Over de PV</span>
                            <ChevronDownIcon
                                :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']"
                                aria-hidden="true" />
                        </PopoverButton>

                        <transition enter-active-class="transition ease-out duration-200"
                            enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0"
                            leave-active-class="transition ease-in duration-150"
                            leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                            <PopoverPanel v-slot="{ close }"
                                class="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                                <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                        <router-link v-for="item in aboutPages" :key="item.name" :to="item.href"
                                            @click="close()"
                                            class="-m-3 flex items-start rounded-lg p-3 hover:bg-yellow-50">
                                            <component :is="item.icon" class="h-6 w-6 flex-shrink-0 text-black"
                                                aria-hidden="true" />
                                            <div class="ml-4">
                                                <p class="text-base font-medium text-gray-900">{{ item.name }}</p>
                                                <p class="mt-1 text-sm text-gray-500">{{ item.description }}</p>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div
                                        class="space-y-6 bg-gray-50 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                        <div class="flow-root">
                                            <a :href="callToAction.href"
                                                class="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100">
                                                <component :is="callToAction.icon"
                                                    class="h-6 w-6 flex-shrink-0 text-black" aria-hidden="true" />
                                                <span class="ml-3">{{ callToAction.name }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </PopoverPanel>
                        </transition>
                    </Popover>

                    <router-link to="/agenda"
                        class="text-base font-medium text-gray-500 hover:text-gray-900 flex gap-1">
                        <CalendarIcon class="h-6 w-6" />
                        <span>Agenda</span>
                    </router-link>
                    <router-link to="/suggesties"
                        class="text-base font-medium text-gray-500 hover:text-gray-900 flex gap-1">
                        <LightBulbIcon class="h-6 w-6" />
                        <span>Suggesties</span>
                    </router-link>

                </PopoverGroup>
                <div class="hidden items-center justify-end lg:flex md:flex-1 lg:w-0">
                    <a href="mailto:pvjumbobreda@jumbo.com"
                        class="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-black px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-slate-900">
                        pvjumbobreda@jumbo.com
                    </a>
                </div>
            </div>
        </div>

        <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
            <PopoverPanel focus v-slot="{ close }"
                class="absolute inset-x-0 top-0 origin-top-right transform p-2 z-20 transition md:hidden">
                <div class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div class="px-5 pt-5 pb-6">
                        <div class="flex items-center justify-between">
                            <div>
                                <img class="h-8 w-auto" :src="require('@/assets/img/png/logo.png')" alt="logo" />
                            </div>
                            <div class="-mr-2">
                                <PopoverButton
                                    class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                                    <span class="sr-only">Close menu</span>
                                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                </PopoverButton>
                            </div>
                        </div>
                        <div class="mt-6">
                            <nav class="grid gap-y-8">
                                <router-link v-for="item in aboutPages" :key="item.name" :to="item.href"
                                    @click="close()"
                                    class="-m-3 flex items-center rounded-md p-3 hover:bg-yellow-50">
                                    <component :is="item.icon" class="h-6 w-6 flex-shrink-0 text-black"
                                        aria-hidden="true" />
                                    <span class="ml-3 text-base font-medium text-gray-900">{{ item.name }}</span>
                                </router-link>
                                <a :href="callToAction.href"
                                    class="-m-3 flex items-center rounded-md p-3 bg-gray-100 hover:bg-gray-50">
                                    <component :is="callToAction.icon" class="h-6 w-6 flex-shrink-0 text-black"
                                        aria-hidden="true" />
                                    <span class="ml-3 text-base font-medium text-gray-900">{{
                                        callToAction.name
                                    }}</span>
                                </a>
                            </nav>
                        </div>
                    </div>
                    <div class="space-y-6 py-6 px-5">
                        <div class="grid grid-cols-2 gap-y-4 gap-x-8">
                            <router-link to="/agenda"
                                @click="close()"
                                class="text-base font-medium text-gray-900 hover:text-gray-700 flex gap-1">
                                <CalendarIcon class="h-6 w-6" />
                                <span>Agenda</span>
                            </router-link>
                            <router-link to="/suggesties"
                                @click="close()"
                                class="text-base font-medium text-gray-900 hover:text-gray-700 flex gap-1">
                                <LightBulbIcon class="h-6 w-6" />
                                <span>Suggesties</span>
                            </router-link>
                        </div>
                        <div>
                            <a href="mailto:pvjumbobreda@jumbo.com"
                                class="flex w-full items-center justify-center rounded-md border border-transparent bg-black px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-slate-900">
                                pvjumbobreda@jumbo.com
                            </a>
                        </div>
                    </div>
                </div>
            </PopoverPanel>
        </transition>
    </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
    Bars3Icon,
    XMarkIcon,
    UserGroupIcon,
    HandRaisedIcon,
    DocumentTextIcon,
    DocumentChartBarIcon,
    UserIcon,
    BuildingOffice2Icon,
    CalendarIcon,
    LightBulbIcon
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

export default {
    components: {
        Popover,
        PopoverButton,
        PopoverGroup,
        PopoverPanel,
        Bars3Icon,
        XMarkIcon,
        ChevronDownIcon,
        UserGroupIcon,
        HandRaisedIcon,
        DocumentTextIcon,
        DocumentChartBarIcon,
        UserIcon,
        BuildingOffice2Icon,
        CalendarIcon,
        LightBulbIcon,
    },
    data() {
        return {
            aboutPages: [
                {
                    name: 'Het bestuur',
                    description: 'Maak kennis met het bestuur.',
                    href: '/het-bestuur',
                    icon: UserGroupIcon,
                },
                {
                    name: 'Reglement',
                    description: 'Hier vind je het meest recente huishoudelijk reglement.',
                    href: '/reglement',
                    icon: HandRaisedIcon,
                },
                {
                    name: 'Algemene voorwaarden',
                    description: "De algemene voorwaarden bevat belangrijke informatie voor u als lid.",
                    href: '/algemene-voorwaarden',
                    icon: DocumentTextIcon
                },
                {
                    name: 'Jaarverslagen',
                    description: 'Informatie over de jaarlijkse ledenvergadering en het jaarverslag.',
                    href: '/jaarverslagen',
                    icon: DocumentChartBarIcon,
                },
            ],
            callToAction: { name: 'Word nu lid!', href: '/lidmaatschap', icon: UserIcon },
        }
    }
}
</script>

<style scoped>
.router-link-active {
    background: #fefce8;
    border-bottom: solid 2px #fef08a;
    color: #111827;
}
</style>