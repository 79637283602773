<template>
    <div class="bg-white">
        <div class="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
            <div
                class="rounded-lg border-l-8 border-l-gray-900 border-r-8 border-r-gray-900 bg-white px-6 py-6 md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
                <div class="xl:w-0 xl:flex-1">
                    <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Inschrijven nieuwsbrief
                    </h2>
                    <p class="mt-3 max-w-3xl text-lg leading-6 text-gray-500">Blijf op de hoogte van het laatste
                        nieuws. Schrijf je nu in voor de nieuwsbrief!</p>
                    <p class="mt-1 text-sm text-gray-500">Je kan op ieder gewenst moment uitschrijven via de link onder aan de pagina.</p>
                </div>
                <div class="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
                    <form v-on:submit.prevent="subscribe">
                        <div
                            class="mb-5 relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-yellow-200 focus-within:ring-1 focus-within:ring-yellow-200">
                            <label for="staff-number"
                                class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-black">Personeelsnummer</label>
                            <input type="text" name="staff-number" id="staff-number"
                                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                placeholder="123456" v-model="staffNumber" required />
                        </div>
                        <div
                            class="mb-5 relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-yellow-200 focus-within:ring-1 focus-within:ring-yellow-200">
                            <label for="email-address"
                                class="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-black">E-mailadres</label>
                            <input type="email" name="email-address" id="email-address"
                                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                                placeholder="adres@domein.com" v-model="email" required />
                        </div>
                        <button type="submit" :disabled="subscribing"
                            class="flex w-full items-center justify-center rounded-md border border-transparent bg-black disabled:bg-gray-400 px-5 py-3 text-base font-medium text-white shadow hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-slate-800">
                            Houd me op de hoogte!
                        </button>
                        <p v-if="successMessage" class="mt-2 text-sm font-medium text-gray-900">{{ successMessage }}</p>
                        <p v-if="errorMessage" class="mt-2 text-sm font-medium text-gray-900">{{ errorMessage }}</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewsletterService from '@/services/newsletter.service';

export default {
    data() {
        return {
            staffNumber: null,
            email: null,

            successMessage: null,
            errorMessage: null,

            subscribing: false
        }
    },
    methods: {
        subscribe() {
            if (!this.staffNumber || !this.email) {
                return;
            }

            this.successMessage = null;
            this.errorMessage = null;
            this.subscribing = true;

            NewsletterService.subscribeToNewsletter(this.staffNumber, this.email).then(
                (res) => {
                    this.staffNumber = null;
                    this.email = null;

                    this.successMessage = res.data;

                    this.subscribing = false;
                },
                (error) => {
                    if (error.response.data.message) {
                        this.errorMessage = error.response.data.message
                    } else {
                        this.errorMessage = error.response.data.errors.Content
                    }
                    this.subscribing = false;
                }
            )
        }
    }
}
</script>