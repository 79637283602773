<template>
  <app-nav />
  <router-view />
  <app-newsletter />
  <app-footer />
</template>

<script>
import AppNav from './components/AppNav.vue';
import AppFooter from './components/AppFooter.vue';
import AppNewsletter from './components/AppNewsletter.vue';

export default {
  components: {
    AppNav,
    AppFooter,
    AppNewsletter
  }
}
</script>

<style>
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    src: url('~@/assets/fonts/Montserrat-ExtraLight.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: italic;
    src: url('~@/assets/fonts/Montserrat-ExtraLightItalic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    src: url('~@/assets/fonts/Montserrat-Light.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: italic;
    src: url('~@/assets/fonts/Montserrat-LightItalic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    src: url('~@/assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    src: url('~@/assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: italic;
    src: url('~@/assets/fonts/Montserrat-SemiBoldItalic.ttf');
}

@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    src: url('~@/assets/fonts/Montserrat-Bold.ttf');
}

#app {
  font-family: 'Montserrat', sans-serif;
}
</style>

