<template>
  <div>
    <app-home-header />
  </div>
</template>

<script>
import AppHomeHeader from '@/components/home/AppHomeHeader.vue';

export default {
  components: {
    AppHomeHeader
  }
}
</script>
