import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/overdepv/gegevens/',
    redirect: { name: 'Home' }
  },
  {
    path: '/het-bestuur',
    name: 'Team',
    component: () => import('../views/TeamView.vue')
  },
  {
    path: '/reglement',
    name: 'Regulations',
    component: () => import('../views/RegulationsView.vue')
  },
  {
    path: '/algemene-voorwaarden',
    name: 'TermsAndConditions',
    component: () => import('../views/TermsAndConditionsView.vue')
  },
  {
    path: '/jaarverslagen',
    name: 'AnnualReports',
    component: () => import('../views/AnnualReportsView.vue')
  },
  {
    path: '/lidmaatschap',
    name: 'Membership',
    component: () => import('../views/MembershipView.vue')
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import('../views/AgendaView.vue')
  },
  {
    path: '/activiteit/:guid',
    name: 'EventDetail',
    component: () => import('../views/EventDetailView.vue')
  },
  {
    path: '/suggesties',
    name: 'SuggestionBox',
    component: () => import('../views/SuggestionBoxView.vue')
  },
  {
    path: '/uitschrijven-nieuwsbrief',
    name: 'UnsubscribeToNewsletter',
    component: () => import('../views/UnsubscribeToNewsletterView.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: () => import('../views/NotFoundView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
