import axios from 'axios';

const API_URL = process.env.VUE_APP_API_BASE_URL + '/member/newsletter/'

class NewsletterService {
    subscribeToNewsletter(staffNumber, email) {
        return axios.put(API_URL + 'subscribe', {
            staffNumber: staffNumber,
            email: email
        });
    }

    unsubscribeToNewsletter(staffNumber, email) {
        return axios.put(API_URL + 'unsubscribe', {
            staffNumber: staffNumber,
            email: email
        });
    }
}

export default new NewsletterService();