<template>
    <div class="bg-white">
        <!-- Header -->
        <div class="relative bg-gray-800 pb-32">
            <div class="absolute inset-0">
                <img class="h-full w-full object-cover" :src="require('@/assets/img/jpg/home.jpg')" alt="DC Breda" />
                <div class="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
            </div>
            <div class="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                <h1 class="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">Welkom bij PV Majstro
                </h1>
                <p class="mt-6 max-w-3xl text-xl text-gray-300">De personeelsvereniging van Jumbo DC Breda! Wij organiseren activiteiten voor de
                    leden, zoals bijvoorbeeld het bezoeken van een sportactiviteit, een creatieve workshop of een
                    anderszins gezellig uitje.</p>
            </div>
        </div>

        <app-event-section />

    </div>
</template>

<script>
import AppEventSection from './AppEventSection.vue';

export default {
    components: {
        AppEventSection
    }
}
</script>