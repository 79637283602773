<template>
    <div>
        <h2 v-if="!hideUpcomingEventsLabel && events && events.length > 0" class="text-base font-semibold leading-6 text-gray-900 invisible lg:visible">
            Aankomende activiteiten</h2>
        <div class="lg:grid lg:grid-cols-12 lg:gap-x-16">
            <div class="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
                <div class="flex items-center text-gray-900">
                    <button type="button" @click="goToPreviousMonth"
                        class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Vorige maand</span>
                        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <div class="flex-auto text-sm font-semibold">{{ currentMonthString }}</div>
                    <button type="button" @click="goToNextMonth"
                        class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Volgende maand</span>
                        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                    <div>M</div>
                    <div>D</div>
                    <div>W</div>
                    <div>D</div>
                    <div>V</div>
                    <div class="text-red-600">Z</div>
                    <div class="text-red-600">Z</div>
                </div>
                <div
                    class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                    <button v-for="(date, dateIdx) in dates" :key="date.date" type="button"
                        :class="['py-1.5 hover:bg-gray-100 focus:z-10 cursor-default', date.isCurrentMonth ? 'bg-white' : 'bg-gray-50', (date.isSelected || date.isToday) && 'font-semibold', date.isSelected && 'text-white', !date.isSelected && date.isCurrentMonth && !date.isToday && 'text-gray-900', !date.isSelected && !date.isCurrentMonth && !date.isToday && 'text-gray-400', date.isToday && !date.isSelected && 'text-yellow-300', dateIdx === 0 && 'rounded-tl-lg', dateIdx === 6 && 'rounded-tr-lg', dateIdx === dates.length - 7 && 'rounded-bl-lg', dateIdx === dates.length - 1 && 'rounded-br-lg']">
                        <time :datetime="date.date"
                            :class="['mx-auto flex h-7 w-7 items-center justify-center rounded-full', date.isSelected && date.isToday && 'bg-yellow-400', date.isSelected && !date.isToday && 'bg-yellow-200']">{{
                                date.date.split('-').pop().replace(/^0/, '') }}</time>
                    </button>
                </div>
                <RouterLink v-if="showGoToAgendaButton && events && events.length > 0" to="/agenda"
                    class="block mt-4   w-full rounded-md bg-yellow-400 px-3 py-2 text-sm font-semibold text-white shadow hover:bg-yellow-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-300">
                    Naar alle activiteiten</RouterLink>
            </div>
            <ol v-if="events && events.length > 0"
                class="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
                <li v-for="event in events" :key="event.id" class="relative flex space-x-6 py-6 xl:static">
                    <img :src="event.image !== '' ? event.image : require('@/assets/img/png/placeholder-image.png')"
                        :alt="event.name" class="h-24 w-24 rounded-lg flex-none object-cover object-center" />
                    <div class="flex-auto">
                        <h3 class="pr-10 font-semibold text-gray-900 xl:pr-0">{{ event.name }}</h3>
                        <dl class="mt-2 flex flex-col text-gray-500 xl:flex-row">
                            <div class="flex items-start space-x-3">
                                <dt class="mt-0.5">
                                    <span class="sr-only">Datum</span>
                                    <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </dt>
                                <dd>
                                    <time :datetime="event.date">{{ formatDate(event.date) }} om {{ event.time }}</time>
                                </dd>
                            </div>
                            <div
                                class="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                                <dt class="mt-0.5">
                                    <span class="sr-only">Locatie</span>
                                    <MapPinIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </dt>
                                <dd>{{ event.location }}</dd>
                            </div>
                        </dl>
                        <div class="mt-2 flex flex-wrap gap-5 items-center">
                            <p v-if="event.registerable" class="text-sm"><span class="text-xl">{{
                                formatCurrency(event.price) }}</span></p>
                            <router-link :to="'/activiteit/' + event.id"
                                class="font-medium text-black hover:text-slate-900">
                                Bekijk deze activiteit
                                <span aria-hidden="true">
                                    <ArrowRightIcon class="h-4 w-4 inline-flex" />
                                </span></router-link>
                        </div>
                    </div>
                </li>
            </ol>
            <div v-else class="mt-4 lg:col-span-7 xl:col-span-8">
                <div class="flex flex-col items-center">
                    <img class="max-h-96" :src="require('@/assets/img/svg/empty-state.svg')" alt="Geen resultaten" />
                    <h2 class="text-gray-700">Op dit moment zijn er geen activiteiten gepland</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    CalendarIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    MapPinIcon,
    ArrowRightIcon
} from '@heroicons/vue/20/solid'

export default {
    components: {
        CalendarIcon,
        ChevronLeftIcon,
        ChevronRightIcon,
        MapPinIcon,
        ArrowRightIcon
    },
    props: {
        events: {
            required: true
        },
        hideUpcomingEventsLabel: {
            type: Boolean,
            required: false,
            default: false
        },
        showGoToAgendaButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            monthNamesDutch: [
                "januari", "februari", "maart",
                "april", "mei", "juni",
                "juli", "augustus", "september",
                "oktober", "november", "december"
            ],

            currentMonthString: '',
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            dates: [],

            convertedEventDates: [],
        }
    },
    created() {
        this.convertedEventDates = this.convertEventDates(this.events);

        this.getMonthName(this.currentMonth);
        this.generateDatesArray(this.currentYear, this.currentMonth);
    },
    methods: {
        getMonthName(month) {
            this.currentMonthString = this.monthNamesDutch[month];
        },
        generateDatesArray(year, month) {
            const firstDayOfMonth = new Date(year, month, 1);

            const dayOfWeek = firstDayOfMonth.getDay();
            const daysToMonday = (dayOfWeek + 6) % 7;
            const startDate = new Date(firstDayOfMonth);
            startDate.setDate(firstDayOfMonth.getDate() - daysToMonday);

            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 6 * 7 - 1);

            let dates = [];
            for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
                let formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
                let dateObj = {
                    date: formattedDate,
                    isCurrentMonth: date.getMonth() === month,
                    isSelected: this.convertedEventDates.includes(formattedDate),
                    isToday: date.getFullYear() === new Date().getFullYear() && date.getMonth() === new Date().getMonth() && date.getDate() === new Date().getDate()
                };

                dates.push(dateObj);
            }

            this.dates = dates;
        },
        goToPreviousMonth() {
            if (this.currentMonth === 0) {
                this.currentYear--;
                this.currentMonth = 11;
            } else {
                this.currentMonth--;
            }
            this.generateDatesArray(this.currentYear, this.currentMonth);
            this.getMonthName(this.currentMonth);
        },
        goToNextMonth() {
            if (this.currentMonth === 11) {
                this.currentYear++;
                this.currentMonth = 0;
            } else {
                this.currentMonth++;
            }
            this.generateDatesArray(this.currentYear, this.currentMonth);
            this.getMonthName(this.currentMonth);
        },
        convertEventDates(events) {
            return events.map(event => {
                const [day, month, year] = event.date.split('-');
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            });
        },
        formatDate(dateString) {
            const [day, month, year] = dateString.split('-');

            const date = new Date(`${year}-${month}-${day}`);

            const options = {
            day: 'numeric',
            month: 'long', 
            year: 'numeric'
            };

            const formatter = new Intl.DateTimeFormat('nl-NL', options);

            return formatter.format(date);
        },
        formatCurrency(amount) {
            const options = {
                style: 'currency',
                currency: 'EUR' 
            };

            const formatter = new Intl.NumberFormat('nl-NL', options);

            return formatter.format(amount);
        },
    }
}
</script>