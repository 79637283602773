import axios from 'axios';

const API_URL = process.env.VUE_APP_API_BASE_URL + '/event/'

class EventService {
    all(pageNumber, pageSize) {
        return axios.get(API_URL + `all?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    }

    get(id) {
        return axios.get(API_URL + id);
    }
}

export default new EventService();